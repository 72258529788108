import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "instructors" }
const _hoisted_2 = { class: "instructors__block" }
const _hoisted_3 = { class: "instructors__main-wrap" }
const _hoisted_4 = { class: "table__mobile-wrap" }
const _hoisted_5 = { class: "table__selects" }
const _hoisted_6 = { class: "mobile-menu-wrap" }
const _hoisted_7 = { class: "instructors__container" }
const _hoisted_8 = { class: "instructors__block-analitics" }
const _hoisted_9 = { class: "instructors__block-analitics-value" }
const _hoisted_10 = { class: "instructors__block-analitics" }
const _hoisted_11 = { class: "instructors__block-analitics-value" }
const _hoisted_12 = { class: "instructors__container" }
const _hoisted_13 = { class: "instructors__block-analitics-value" }
const _hoisted_14 = { class: "instructors__block-analitics" }
const _hoisted_15 = { class: "instructors__block-analitics-value" }
const _hoisted_16 = { class: "instructors__container" }
const _hoisted_17 = { class: "instructors__block-analitics-value" }
const _hoisted_18 = { class: "instructors__container instructors__container-second" }
const _hoisted_19 = { class: "instructors__block-analitics instructors__block-analitics-chart" }
const _hoisted_20 = { class: "instructors__chat-line-wrap" }
const _hoisted_21 = ["src"]
const _hoisted_22 = { class: "instructors__block-analitics instructors__block-analitics-chart" }
const _hoisted_23 = { class: "instructors__chat-line-wrap" }
const _hoisted_24 = { class: "instructors__chat-title" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { class: "instructors__container" }
const _hoisted_27 = { class: "instructors__block-analitics instructors__block-analitics-chart" }
const _hoisted_28 = { class: "instructors__block-analitics-title-block" }
const _hoisted_29 = { class: "instructors__chat-cirkle-wrap" }
const _hoisted_30 = { class: "circle-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminPanelNavMenu = _resolveComponent("AdminPanelNavMenu")!
  const _component_AnalyticsNavMenu = _resolveComponent("AnalyticsNavMenu")!
  const _component_AnaliticMenuSVG = _resolveComponent("AnaliticMenuSVG")!
  const _component_DateRangePicker = _resolveComponent("DateRangePicker")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AdminPanelNavMenu)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_AnalyticsNavMenu, { class: "instructors__nav-menu desctop-menu" }),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_AnaliticMenuSVG, {
              class: "mobile-mene-svg",
              onClick: _ctx.toggleMenu
            }, null, 8, ["onClick"]),
            _createElementVNode("div", {
              class: _normalizeClass({
                'instructors__nav-menu': true,
                'mobile-menu': true,
                activeMobileMenu: _ctx.isMenuActive,
              })
            }, [
              _createVNode(_component_AnalyticsNavMenu)
            ], 2)
          ])), [
            [_directive_click_outside, _ctx.closeMenu]
          ]),
          _createVNode(_component_DateRangePicker, {
            "select-date-fill": _ctx.selectDateFill,
            onOnSelectStaticRange: _ctx.selectStaticRange,
            onCloseCalendar: _ctx.closeCalendar
          }, null, 8, ["select-date-fill", "onOnSelectStaticRange", "onCloseCalendar"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _cache[4] || (_cache[4] = _createElementVNode("div", null, "OVERALL STUDENTS", -1)),
            _createElementVNode("div", _hoisted_9, _toDisplayString(this.analyticsData.overall_students), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[5] || (_cache[5] = _createElementVNode("div", null, "STUDENTS", -1)),
            _createElementVNode("div", _hoisted_11, _toDisplayString(this.analyticsData.current_students), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", {
            class: "instructors__block-analitics instructors__block-analitics-hover",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (
              _ctx.goToDetailPage(
                '/admin/analytics/students/students-moved-up-to-next-level'
              )
            ))
          }, [
            _cache[6] || (_cache[6] = _createElementVNode("div", null, "Number of students moved up", -1)),
            _createElementVNode("div", _hoisted_13, _toDisplayString(this.analyticsData.students_moved_up), 1)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _cache[7] || (_cache[7] = _createElementVNode("div", null, "% of students with updated skill", -1)),
            _createElementVNode("div", _hoisted_15, _toDisplayString(this.analyticsData.updated_skill_percentage) + "% ", 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", {
            class: "instructors__block-analitics instructors__block-analitics-hover",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (
              _ctx.goToDetailPage(
                '/admin/analytics/instructor/most-reviewed-instructors'
              )
            ))
          }, [
            _cache[8] || (_cache[8] = _createElementVNode("div", null, "Reviews", -1)),
            _createElementVNode("div", _hoisted_17, _toDisplayString(this.analyticsData.reviews_count), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _cache[9] || (_cache[9] = _createElementVNode("div", null, "STUDENTS BY LEVEL", -1)),
            _createElementVNode("div", _hoisted_20, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartDataStudentsByLevel, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.id,
                  class: "instructors__chat-line-block"
                }, [
                  _createElementVNode("img", {
                    src: item.icon_image,
                    alt: ""
                  }, null, 8, _hoisted_21),
                  _createElementVNode("div", {
                    class: "instructors__chat-line",
                    style: _normalizeStyle({ height: item.count * 1 + 'px' })
                  }, null, 4),
                  _createElementVNode("div", null, _toDisplayString(item.count), 1)
                ]))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _cache[10] || (_cache[10] = _createElementVNode("div", null, "Students by time spent in the program", -1)),
            _createElementVNode("div", _hoisted_23, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartDataStudentsByTimeToSpent, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.id,
                  class: "instructors__chat-line-block instructors__chat-line-time-to-spent-block"
                }, [
                  _createElementVNode("div", _hoisted_24, _toDisplayString(item.title), 1),
                  _createElementVNode("div", {
                    class: "instructors__chat-line instructors__chat-hover",
                    style: _normalizeStyle({ height: item.count * 1 + 'px' }),
                    onClick: ($event: any) => (_ctx.goToTimeSpent(item.title))
                  }, null, 12, _hoisted_25),
                  _createElementVNode("div", null, _toDisplayString(item.count), 1)
                ]))
              }), 128))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("div", {
                class: "instructors__block-analitics-title-faster",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (
                  _ctx.goToDetailPage('/admin/analytics/students/fast-learners')
                ))
              }, [
                _cache[11] || (_cache[11] = _createTextVNode(" Fast Learners ")),
                _createElementVNode("span", null, _toDisplayString(this.analyticsData.fast_learners), 1)
              ]),
              _createElementVNode("div", {
                class: "instructors__block-analitics-title-slow",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.goToDetailPage('/admin/analytics/students/slow-movers')))
              }, [
                _cache[12] || (_cache[12] = _createTextVNode(" Slow Movers ")),
                _createElementVNode("span", null, _toDisplayString(this.analyticsData.slow_movers), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_29, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(1, (item) => {
                return _createElementVNode("div", {
                  key: item.id,
                  class: "instructors__chat-cirkle-block"
                }, [
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("div", {
                      class: "circle",
                      style: _normalizeStyle(_ctx.circleStyle)
                    }, null, 4)
                  ])
                ])
              }), 64))
            ])
          ])
        ])
      ])
    ])
  ]))
}