
import { defineComponent, ref, computed } from "vue";
import AdminPanelNavMenu from "@/components/molecules/AdminPanelNavMenu.vue";
import AnaliticMenuSVG from "@/assets/svg/analitic-menu.svg?inline";
import SelectArrow from "@/assets/svg/select-arrow.svg?inline";
import DefaultPopup from "@/components/molecules/DefaultPopup.vue";
import AlertBlock from "@/components/molecules/AlertBlock.vue";
import AnalyticsNavMenu from "@/components/molecules/AnalyticsNavMenu.vue";
import Select from "@/components/atoms/CustomSelect.vue";
import Calendar from "@/components/molecules/Calendar.vue";
import DateRangePicker from "@/components/molecules/DateRangePicker.vue";
import vClickOutside from "click-outside-vue3";
import { Chart, registerables } from "chart.js";
import { BarChart, useBarChart } from "vue-chart-3";
Chart.register(...registerables);

export default defineComponent({
  name: "HomeDashboard",
  data() {
    return {
      page: 1,
      isCalendarVisible: false,
      analyticsData: {},
      analyticsMeta: null,
      erroranAlytics: null,
      filters: "",
      filtersDate: "",
      isMenuActive: false,
      period: 1,
      chartDataStudentsByLevel: [],
      chartDataStudentsByTimeToSpent: [],
      percentage: null,
    };
  },
  components: {
    AdminPanelNavMenu,
    AnalyticsNavMenu,
    DefaultPopup,
    AlertBlock,
    Select,
    Calendar,
    SelectArrow,
    AnaliticMenuSVG,
    DateRangePicker,
    BarChart,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    circleStyle() {
      return {
        background: `conic-gradient(#0AC2A3 ${
          this.percentage * 3.6
        }deg, #0066cc 0deg)`,
      };
    },
  },
  setup() {
    const data = ref([30, 40, 60, 70, 5]);

    const chartData = computed(() => ({
      labels: ["Paris", "Nîmes", "Toulon", "Perpignan", "Autre"],
      datasets: [
        {
          data: data.value,
          backgroundColor: [
            "#77CEFF",
            "#0079AF",
            "#123E6B",
            "#97B0C4",
            "#A5C8ED",
          ],
        },
      ],
    }));

    const { barChartProps, barChartRef } = useBarChart({
      chartData,
    });

    function shuffleData() {
      data.value = shuffle(data.value);
    }

    return { shuffleData, barChartProps, barChartRef };
  },
  mounted() {
    this.getAnalytics();
  },
  methods: {
    getAnalytics(params, sort) {
      let searchParam = `?page=${this.page}&n=20&search=`;
      let startDate = "";
      let endDate = "";

      const currentDate = new Date();
      const pastDate = new Date();
      if (this.period === 1) {
        pastDate.setDate(currentDate.getDate() - 7);
      } else if (this.period === 2) {
        pastDate.setDate(currentDate.getDate() - 30);
      } else if (this.period === 3) {
        pastDate.setDate(currentDate.getDate() - 1000);
      }

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      if (sort === undefined) {
        sort = "";
      }

      startDate =
        this.selectedStartDate !== undefined
          ? this.selectedStartDate
          : formatDate(pastDate);
      endDate =
        this.selectedEndDate !== undefined
          ? this.selectedEndDate
          : formatDate(currentDate);

      params =
        searchParam +
        "&n=20&sort=" +
        sort +
        this.filters +
        this.filtersDate +
        `&start_date=${startDate}` +
        `&end_date=${endDate}`;

      this.$store.dispatch("analytics/getMainDashboard", params).then(
        (res) => {
          this.analyticsData = res.data;
          this.analyticsMeta = res.meta;
          this.chartDataStudentsByLevel = this.analyticsData.students_by_level;
          this.chartDataStudentsByTimeToSpent =
            this.analyticsData.students_by_time_spent;
          this.percentage =
            (this.analyticsData.slow_movers /
              this.analyticsData.fast_learners) *
            50;
        },
        (error) => {
          this.erroranalytics = error.response.data.errors;
        }
      );
    },
    handlePage(page) {
      this.page = page;
      this.getInstructors();
    },
    handleOptionSelected(filterKey, selectedOption, callback) {
      const newFilter = `filter[${filterKey}]=${selectedOption.id}`;
      const regex = new RegExp(`filter\\[${filterKey}\\]=[^&]*`);
      if (selectedOption.id !== undefined) {
        if (regex.test(this.filters)) {
          this.filters = this.filters.replace(regex, newFilter);
        } else {
          this.filters += `${
            this.filters.includes("?") ? "&" : "?"
          }${newFilter}`;
        }
      } else
        this.filters = this.filters
          .replace(regex, "")
          .replace(/&{2,}/g, "&")
          .replace(/[?&]$/, "");
      if (callback) callback(selectedOption.id);
      this.getAnalytics();
    },
    handleOptionSkillsSelected(selectedOption) {
      this.filtersScills = `&filter[skill_id]=${selectedOption.id}`;
      if (selectedOption.id !== undefined) {
        this.getAnalytics();
      }
    },
    selectDateFill(date, month, year) {
      const selectedDate = new Date(year, month, date.value);
      const currentDate = new Date();
      if (selectedDate > currentDate) {
        return;
      }
      if (date.value !== "") {
        const selectedDate = new Date(year, month, date.value);
        if (!this.selectedStartDate) {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
        } else if (
          !this.selectedEndDate &&
          selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate() >=
            this.selectedStartDate
        ) {
          this.selectedEndDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
        } else {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
          this.selectedEndDate = null;
        }
      }
      if (this.selectedEndDate) {
        this.filtersDate = `&start_date=${this.selectedStartDate}&end_date=${this.selectedEndDate}`;
        this.getAnalytics();
      }
    },
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
    closeMenu() {
      this.isMenuActive = false;
    },
    closeCalendar() {
      this.isCustomPeriodsCalendarVisible = false;
      this.isCalendarVisible = false;
    },

    selectStaticRange(period) {
      this.period = period;
      this.getAnalytics();
    },
    goToDetailPage(url) {
      window.open(`${url}`, "_blank");
    },
    goToTimeSpent(period) {
      window.open(`/admin/analytics/students/time-spent-in-program?period${period}`, "_blank");
    },
  },
});
